import axios from 'axios';

export const indexTeamInvite = (teamId) => (page = 1) => {
    const url = `v1/coach/invite?filter[team]=${teamId}&page=${page}`

    return axios.get(url).then(response => response.data)
}

export const indexInvite = (page = 1) => {
    const url = `v1/coach/invite?page=${page}`

    return axios.get(url).then(response => response.data)
}

export function createInvites(teamId, form) {
    const url = `v1/coach/invite`

    form.team_id = teamId

    return axios.post(url, form)
}

export function resendInviteEmail(inviteUuid) {
    const url = `v1/coach/invite/${inviteUuid}?_method=patch`

    return axios.post(url)
}

export function revokeInvite(inviteUuid) {
    const url = `v1/coach/invite/${inviteUuid}?_method=delete`

    return axios.post(url)
}