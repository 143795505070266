<template>
  <DashboardWrapper>
    <div class="container mx-auto">
      <div class="mx-4 sm:mx-8">
        <div class="flex items-center mb-4 mx-4 sm:mx-0">
          <h2 class="text-white uppercase text-xl font-extrabold">
            {{ team?.name }}
          </h2>
          <div
            class="numberCircle h-6 w-6 ml-3 flex justify-center items-center"
          >
            <span class="text-white text-sm font-medium"></span>
          </div>
        </div>

        <div class="mx-4 sm:mx-8">
          <div class="flex justify-end">
            <div
              @click="state.isInviteOpen = true"
              class="my-auto text-white cursor-pointer flex group"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 mr-1 transition-all duration-150 group-hover:text-gray-400 transform group-hover:-translate-y-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <p
                class="mr-6 group-hover:text-gray-400 transition-all duration-150"
              >
                <i18n-t keypath="team.show.trainees.newInvite" />
              </p>
            </div>
            <SearchInput
              v-model:value="state.searchQuery"
              :placeholder="$t('team.show.trainees.search')"
              theme="dark"
            />
          </div>
        </div>

        <div class="text-white">
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div
                class="py-6 align-middle inline-block min-w-full mx-4 md:mx-0 sm:px-6 lg:px-8"
              >
                <div class="shadow overflow-hidden">
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th
                          v-for="row in rows"
                          :key="row.key"
                          class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div
                            class="flex items-center cursor-pointer"
                            @click="sort(row.key)"
                          >
                            <div>{{ row.text }}</div>
                            <div v-if="state.currentSort === row.key">
                              <svg
                                class="ml-1 w-4 h-4 transform duration-200"
                                :class="[
                                  state.currentSortDir == 'asc'
                                    ? '-rotate-180'
                                    : '',
                                ]"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M19 9l-7 7-7-7"
                                ></path>
                              </svg>
                            </div>
                            <div v-else class="ml-1 w-4 h-4"></div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-500">
                      <template v-if="members.length">
                        <tr v-for="member in sortedMembers" :key="member.name">
                          <MemberRow
                            :member="member"
                            @delete="onDeleteRow"
                            @resend="resendInvite"
                            :isSending="
                              member.uuid &&
                                state.inviteIsSendingSet.has(member.uuid)
                            "
                          />
                        </tr>
                      </template>
                      <tr v-else>
                        <td
                          :colspan="rows.length"
                          class="px-4 py-5 whitespace-nowrap text-sm font-medium text-center text-white"
                        >
                          <i18n-t keypath="team.show.trainees.empty" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal v-model:isOpen="state.isInviteOpen">
          <div class="max-w-xl mx-auto">
            <h2 class="text-black uppercase text-xl font-extrabold mb-8">
              <i18n-t keypath="team.show.invite.title" />
            </h2>
            <form @submit.prevent="handleSubmit">
              <div v-for="(email, index) in form.emails" :key="index">
                <div class="flex items-center space-x-2">
                  <TextInput
                    v-model:value="form.emails[index]"
                    v-model:errors="errors[`emails.${index}`]"
                    :label="$t('team.show.invite.email.label')"
                    :placeholder="$t('team.show.invite.email.placeholder')"
                    border="enable"
                    class="w-full mb-4"
                  />
                  <button
                    @click.prevent="removeInviteEmailField(index)"
                    class="mt-3 text-black"
                  >
                    <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="mb-6">
                <button
                  @click.prevent="addInviteEmailField"
                  class="text-black button-white button-small flex items-center"
                >
                  <svg
                    class="w-6 h-6"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span class="ml-1">Toevoegen</span>
                </button>
              </div>

              <button type="submit" class="button">
                <i18n-t keypath="team.show.invite.submit" />
              </button>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  </DashboardWrapper>
</template>

<script>
import { defineComponent } from "vue";
import DashboardWrapper from "@/wrappers/Dashboard";
import TextInput from "@/components/forms/TextInput.vue";
import MemberRow from "@/components/MemberRow.vue";
import SearchInput from "@/components/forms/SearchInput.vue";
import Modal from "@/wrappers/partials/Modal.vue";
import {
  indexTeamInvite,
  createInvites,
  resendInviteEmail,
  revokeInvite,
} from "@/helper/api/TeamInviteHelper";
import { getTeam, removeTraineeFromTeam } from "@/helper/api/TeamHelper";
import messages from "@/helper/store/messageHelper";

export default defineComponent({
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  created() {
    this.getTeam();
    this.resetInvitesList();
  },
  data() {
    return {
      form: {
        emails: [""],
      },
      rows: [
        { key: "name", text: "Naam" },
        { key: "email", text: "Email" },
        { key: "birthdate", text: "Geboortedatum" },
        { key: "status", text: "Status" },
      ],
      errors: {},
      state: {
        searchQuery: "",

        currentSort: "name",
        currentSortDir: "asc",

        isInviteOpen: false,

        inviteIsSendingSet: new Set(),
      },
      team: undefined,
      invites: undefined,
    };
  },
  methods: {
    async getTeam() {
      this.team = await getTeam(this.id);
    },
    handleSubmit() {
      createInvites(this.id, this.form)
        .then(() => {
          this.resetInvitesList();
          this.form.emails = [""];
        })
        .catch((err) => {
          this.errors = err.response?.data.errors;
        });
    },

    onDeleteRow(row) {
      if (row.status === "active") {
        this.removeTrainee(row);
      } else if (row.status === "invited") {
        this.revokeInvite(row);
      }
    },

    sort(s) {
      if (s === this.state.currentSort) {
        this.state.currentSortDir =
          this.state.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.state.currentSort = s;
    },

    async removeTrainee(trainee) {
      if (
        !confirm(
          this.$t("team.show.trainees.removeConfirm", { name: trainee.name })
        )
      )
        return;
      await removeTraineeFromTeam(this.id, trainee.id);

      await this.getTeam();
      messages.success(this.$t("team.show.trainees.removeSuccess"));
    },
    async resendInvite(invite) {
      if (this.state.inviteIsSendingSet.has(invite.uuid)) {
        return;
      }

      this.state.inviteIsSendingSet.add(invite.uuid);
      try {
        await resendInviteEmail(invite.uuid);
        messages.success(this.$t("team.show.invite.success"));
      } finally {
        this.state.inviteIsSendingSet.delete(invite.uuid);
      }
    },
    async revokeInvite(invite) {
      if (
        !confirm(
          this.$t("team.show.invite.removeConfirm", { name: invite.email })
        )
      )
        return;

      await revokeInvite(invite.uuid);
      this.resetInvitesList();
      messages.success(this.$t("team.show.invite.removeSuccess"));
    },
    async resetInvitesList() {
      this.invites = await indexTeamInvite(this.id)();
    },
    addInviteEmailField() {
      this.form.emails.push("");
    },
    removeInviteEmailField(index) {
      this.form.emails.splice(index, 1);
    },
  },
  computed: {
    members: function() {
      const trainees = this.team?.trainees ?? [];
      const invites = this.invites?.invites ?? [];
      return [
        ...trainees.map((t) => ({ ...t, status: "active" })),
        ...invites.map((t) => ({ ...t, status: "invited" })),
      ];
    },
    indexInvite() {
      return indexTeamInvite(this.id);
    },
    sortedMembers: function() {
      let cs = this.state.currentSort;
      let dir = this.state.currentSortDir;
      return this.resultQuery.slice().sort((a, b) => {
        let modifier = 1;
        if (dir === "desc") modifier = -1;
        if (a[cs] < b[cs]) return -1 * modifier;
        if (a[cs] > b[cs]) return 1 * modifier;
        return 0;
      });
    },

    resultQuery() {
      if (this.state.searchQuery) {
        return this.members.filter((member) => {
          return this.state.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => member.name?.toLowerCase().includes(v));
        });
      } else {
        return this.members;
      }
    },
  },
  components: {
    DashboardWrapper,
    MemberRow,
    TextInput,
    SearchInput,
    Modal,
  },
});
</script>
