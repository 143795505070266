import axios from 'axios';


export function indexTeam(page = 1) {
    const url = `v1/coach/team?page=${page}` 

    return axios.get(url).then(response => response.data)
}

export function createTeam(form) {
    const url = `v1/coach/team` 
    
    return axios.post(url, form).then(response => response.data)
}

export function getTeam(teamId) {
    const url = `v1/coach/team/${teamId}` 
    
    return axios.get(url).then(response => response.data.team)
}

export function removeTraineeFromTeam(teamId, traineeId) {
    const url = `v1/coach/team/${teamId}/${traineeId}?_method=delete`;

    return axios.post(url);
}