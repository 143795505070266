<template>
  <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
    {{ member.name }}
  </td>
  <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
    {{ member.email }}
  </td>
  <td class="px-4 py-4 whitespace-nowrap text-sm font-medium text-white">
    {{ member.birth_day }}
  </td>
  <td class="px-4 py-4 whitespace-no-wrap">
    <span
      class="inline-block px-3 py-2 text-xs font-medium rounded text-white"
      :class="{
        'bg-scorelitgreen': member.status === 'active',
        'bg-scorelitred': member.status === 'invited',
      }"
    >
      {{ statusLabel }}
    </span>
  </td>
  <td class="text-right">
    <div class="flex justify-end pr-6">
      <span
        v-if="member.status === 'invited'"
        @click="$emit('resend', member)"
        :class="{ 'animate-spin': isSending }"
        :title="$t('team.show.invite.resend')"
      >
        <svg
          class="h-5 w-5"
          :class="{
            'text-orange': isSending,
            'text-white transform transition-all duration-150 hover:scale-125 hover:text-gray-400 cursor-pointer': !isSending,
          }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="currentColor"
        >
          <path
            d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"
          />
        </svg>
      </span>
      <svg
        @click="$emit('delete', member)"
        class="h-5 w-5 ml-3 text-white transform transition-all duration-150 hover:scale-125 hover:text-scorelitred cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
  </td>
</template>

<script>
import i18n from "@/i18n";
const $t = i18n.global.t;

export default {
  name: "MemberRow",
  props: ["member", "isSending"],
  emits: ["resend", "delete"],

  computed: {
    statusLabel: function() {
      return this.member?.status === "active"
        ? $t("team.show.trainees.active")
        : $t("team.show.trainees.invited");
    },
  },
};
</script>
