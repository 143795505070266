<template>
  <div>
    <label
      v-if="label"
      :for="label"
      class="block text-xs sm:text-sm leading-5 font-medium"
      :class="theme === 'dark' ? 'text-white' : 'text-gray-900'"
      >{{ label }}</label
    >
    <div
      class="relative rounded-md shadow-sm"
      :class="{ 'border-grays-400 border': border === 'enable' }"
    >
      <div class="relative text-gray-400 focus-within:text-gray-600">
        <div
          class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center "
        >
          <!-- Heroicon name: solid/search -->
          <svg
            class="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clip-rule="evenodd"
            />
          </svg>
        </div>

        <input
          ref="input"
          :id="label"
          type="search"
          :value="value"
          @input="updateValue()"
          :placeholder="placeholder"
          :disabled="!!disabled"
          :autofocus="autofocus !== undefined"
          class="form-input block w-full p-2 sm:py-4 pl-10 text-xs sm:text-sm sm:leading-5 rounded-md shadow-sm"
          :class="
            theme === 'dark' ? 'bg-scorelitgray text-gray-300' : 'bg-white'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: [
    "value",
    "label",
    "placeholder",
    "disabled",
    "autofocus",
    "theme",
    "border",
  ],

  methods: {
    updateValue() {
      this.$emit("update:value", this.$refs.input.value);
    },
    clearErrors() {
      this.$emit("update:errors", []);
    },
  },
};
</script>

<style scoped>
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
